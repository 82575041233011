/**
 * Const with params element
 * Slider hero component
 * Display a slider of mulitple text with Slick
 */
/* Import section */
import React from "react"
import { IconContext } from "react-icons"
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs"
import { LazyLoad } from "react-observer-api"
import Slider from "react-slick"
import "../styles/components/sliderhero.scss"

/* Declaration function */
const Sliderhero = ( { keySlider, arrText, classSlider } ) => {
  const settings = {
    arrows: true,
    dots: true,
    fade: true,
    infinite: true,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 100,
    swipe: true,
  }

  return (
    <LazyLoad>
      <Slider key={keySlider} className={`slider-hero ${classSlider}`} {...settings} >
        {arrText.map( (text, index )  => (
          <div key={index} id={index} dangerouslySetInnerHTML={{ __html:text.textHero}}/>
          )
        )}
      </Slider>
    </LazyLoad>
  )
}

/* Export function */
export default Sliderhero

function NextArrow(props) {
  const { className, onClick, onKeyDown } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="presentation"
    >
      <IconContext.Provider value={{ className: "icon-arrow" }}>
        <BsArrowRightShort />
      </IconContext.Provider>
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick, onKeyDown } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="presentation"
      >
      <IconContext.Provider value={{ className: "icon-arrow" }}>
        <BsArrowLeftShort />
      </IconContext.Provider>
    </div>
  );
}
